.landing_bg_shade {
  background: linear-gradient(
    93.58deg,
    #f8f3f3 10.8%,
    rgba(253, 253, 253, 0) 83.68%
  );
}

.application {
  background: linear-gradient(
      260.85deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(243, 243, 243, 0.8);
}
.empty_search_main {
  max-width: 312px;
}

.react-responsive-modal-modal {
  max-width: 1100px !important;
  display: inline-grid;
}
.Equipements_style {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.Equipements_style_inner {
  max-width: 185px;
  min-width: 89px;
}

.search_page_main_section {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.custom-checkbox {
  appearance: none;
  width: 22px;
  height: 22px;
  border: 1px solid #90a3bf;
  border-radius: 6px;
  cursor: pointer;
}

.custom-checkbox:checked {
  appearance: none;
  width: 22px;
  height: 22px;
  border: 1px solid #ff8c00;
  border-radius: 6px;
  background-color: #ff8c00;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #ff8c00;
}

.custom-checkbox:checked::before {
  content: "✔";
  display: block;
  width: 20px;
  height: 0px;
  text-align: center;
  line-height: 19px;
  color: white;
}

.animation-slideUp {
  animation-name: slideUp;
  animation-duration: 0.4s;
}

@keyframes slideUp {
  from {
    transform: translateY(100%); /* Start outside the page */
  }
  to {
    transform: translateY(0); /* End at bottom: 0 */
  }
}

.animation-slideDown {
  animation-name: slideDown;
  animation-duration: 0.4s;
}

@keyframes slideDown {
  from {
    transform: translateY(0); /* Start outside the page */
  }
  to {
    transform: translateY(100%); /* End at bottom: 0 */
  }
}

.rdp-months {
  justify-content: center;
}
.rdp-month {
  width: 100%;
  max-width: 400px;
}

.rdp-table {
  width: 100%;
  max-width: 400px !important;
  margin-top: 20px !important;
}

@media (max-width: 450px) {
  .react-responsive-modal-modal {
    padding: 0px !important;
    margin: 0px !important;
  }
}

@media (max-width: 640px) {
  .searchPage_image_style {
    height: auto !important;
  }
}

@media (max-width: 550px) {
  .Equipements_style {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 450px) {
  .Equipements_style {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dateTime_show_input {
    font-size: 16px !important;
  }
}

@media (max-width: 400px) {
  .Caractéristiques_style {
    grid-template-columns: auto;
  }
}

#number_setting {
  transform: rotate(15deg);
  text-align: center;
  font-family: "Gotham Rounded Bold";
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.4;
  color: transparent;
  -webkit-text-stroke: 2px #ff8c00;
}

#number_setting:hover {
  color: #ff8c00;
  opacity: 0.7;
}

.change-arrow {
  width: 248px;
  height: 193px;
}

@media (min-width: 768px) and (max-width: 960px) {
  .change-arrow {
    width: 180px;
    height: 150px;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  #number_setting {
    color: #ff8c00;
    font-size: 75px;
    opacity: 0.7;
  }
}

@media (max-width: 424px) {
  #number_setting {
    color: #ff8c00;
    font-size: 70px;
    opacity: 0.4;
  }
}

/* Scrollbar */

.scrollbar-style {
  scrollbar-gutter: stable;
}

.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar-style::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/* Input Range */

input[type="range"]::-webkit-slider-runnable-track {
  background: #ff8c00;
  height: 15px;
  width: 100%;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  background-color: #ff8c00;
  height: 25px;
  width: 25px;
  border-radius: 20px;
  position: relative;
  top: -5px;
  border: 4px solid #f3f3f3;
}

.font-family-open-sans {
  font-family: "Open Sans" !important;
}

.rc-slider-rail {
  height: 15px !important;
}

.rc-slider-track {
  height: 15px !important;
}

.rc-slider-handle {
  height: 25px !important;
  width: 25px !important;
  border: solid 4px #f3f3f3 !important;
  opacity: 1 !important;
}

.font-weight-400 {
  font-weight: 400;
}

.heading {
  font-weight: 700;
  margin-left: 24px;
  margin-top: 10px;
}
.section_margin_bottom {
  margin-bottom: 20px;
}
.sub_heading {
  font-weight: 700;
  margin-left: 24px;
  margin-top: 10px;
  font-size: 16px;
}
.inner_sub_heading {
  font-weight: 700;
  font-size: 15px;
  margin-left: 24px;
  margin-top: 10px;
}
.inner_sub_heading_simple {
  font-size: 15px;
  font-weight: 700;
}

.list_items {
  margin-left: 40px;
}
.list_text_margin {
  margin-left: 10px;
}
.list-circle {
  list-style-type: circle !important;
}

.table-custom-styling,
td {
  border: #ffffff solid 2px;
  padding-left: 7px;
  padding-bottom: 15px;
}

.table-col-color {
  background-color: #ed7d31;
  color: #ffffff;
}

.meta-ads-mobile-section{
  display: none;
}

.meta-ads-main-section{
	position: absolute;
	margin-left: 5rem;
	margin-top: 80px;
  width: 425px;
}
.meta-ads-heading-section {
	max-width: 425px;
	font-family: Open Sans;
	font-size: 36px;
	font-weight: 600;
	line-height: 49px !important;
	margin-bottom: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #444444;
	margin-top: 30px;
}

@media screen and (max-width: 820px){
  .meta-ads-mobile-section {
		display: block;
		max-width: 820px;
		margin-left: auto;
		margin-right: auto;
	}
  .meta-ads-main-section{
 display: none;
  }
}

@media screen and (max-width: 1250px) {
	.meta-ads-heading-section {
		font-size: 30px;
		line-height: 40px ;
		margin-top: 15px;
	}
  .meta-ads-apps-button-section {
		margin-top: 30px;
	}
}

@media screen and (max-width: 1050px) {
	
	.meta-ads-heading-section {
		font-size: 20px;
		line-height: 30px;
		margin-top: 0px;
		max-width: 220px;
		line-height: 35px !important;
	}

}

@media screen and (max-width: 900px) {

	.meta-ads-heading-section{
		line-height: 30px;
	}
  .meta-ads-apps-button-section {
		margin-top: 15px;
	}
	
}