@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
	font-family: "Open Sans";
}
.footerFont {
	font-family: "Open Sans" !important;
}
/* .popinFont{
  font-family: 'Poppins' !important;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Popup CSS */
.react-responsive-modal-modal {
	background-color: transparent !important;
	box-shadow: none !important;
}

.react-responsive-modal-closeButton {
	display: none !important;
}

/* Srolllbar CSS */

/* Firefox */
.search_scroll {
	scrollbar-width: thin;
	scrollbar-color: #e6e6e6;
	margin-right: 7px;
}

.select-wrapper select {
	-webkit-appearance: none;
	appearance: none;
}

.select-wrapper {
	position: relative;
	border-radius: 5px;
	border: 1px solid #e0e7ed;
}

.select-wrapper::after {
	content: url("/src/assests/svg/downarroe.svg");
	font-size: 5px;
	top: 45%;
	position: absolute;
}

/* Chrome, Edge, and Safari */
.search_scroll::-webkit-scrollbar {
	width: 7.91px;
}

.search_scroll::-webkit-scrollbar-track {
	background: #e6e6e6;
	border-radius: 5px;
}

.search_scroll::-webkit-scrollbar-thumb {
	background-color: #b3b3b3;
	border-radius: 14px;
}

/* time Slote scrollbar */
.time_scroll::-webkit-scrollbar {
	width: 7.91px;
}

.time_scroll::-webkit-scrollbar-track {
	background: #e6e6e6;
}

.time_scroll::-webkit-scrollbar-thumb {
	background-color: #b3b3b3;
	border-radius: 14px;
}

/* Admin scroll bar */

.admin_sidrbar_scroll {
	scrollbar-width: auto;
	scrollbar-color: #e6e6e6;
	cursor: context-menu;
}

/* Chrome, Edge, and Safari */
.admin_sidrbar_scroll::-webkit-scrollbar {
	width: 5px;
}

.admin_sidrbar_scroll::-webkit-scrollbar-track {
	background: #e6e6e6;
	border-radius: 5px;
}

.admin_sidrbar_scroll::-webkit-scrollbar-thumb {
	background-color: #b3b3b3;
	border-radius: 14px;
}

/* Swiper CSS */

.swiper-button-next,
.swiper-button-prev {
	background-color: white;
	border-radius: 50px;
	/* background-color: rgba(255, 255, 255, 0.5); */
	padding: 22px;
	color: #000 !important;
	fill: black !important;
	stroke: black !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	font-size: 20px !important;
}

/* .contents .object-cover {

} */
.notifyMail {
	/* position: fixed; */
	margin-top: 20px;
	width: 100%;
	bottom: 12px;
	background: #3c8d00;
	/* padding: 6px 16px 6px 3px; */
	border-radius: 11px;
	color: #fff;
	max-width: 940px;
	/* transform: translateX(-50%); */
	/* left: 50%; */
}
.notifyMail h3 {
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 500;
}
.notifyMail .notification {
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 400;
}
.rdp-caption_label {
	font-size: 16px !important;
	text-transform: capitalize;
}
.rdp-weeknumber,
.rdp-day {
	font-weight: 400 !important;
	font-size: 14px !important;
}
.rdp-head_cell {
	font-weight: 600 !important;
	color: #3c3c434d !important;
}
.cstmPos {
	bottom: calc(100% + 25px);
}

.cstmPosTop {
	top: calc(100% + 25px);
}
.socialBtn {
	/* max-width: 170px;
    width: 100%; */
	margin: 5px;
	display: inline-block;
	height: 50px;
}

.bgImgBnr {
	background-image: url("/src/assests/newDesign/group-rent-bg.png");
	background-size: 100% 100%;
	background-position: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
.detailDesc p,
.detailDesc ul {
	margin: revert;
	padding: revert;
	list-style: revert;
}

.data_pick2 {
	left: -110% !important;
}
@media screen and (max-width: 460px) {
	.socialBtn {
		margin: 5px;
		display: inline-block;
		height: 40px;
	}
}
@media screen and (max-width: 420px) {
	.socialBtn {
		margin: 5px;
		display: inline-block;
		height: 36px;
	}
}
@media screen and (max-width: 400px) {
	.bgImgBnr {
		background-size: 373% 43%;
		background-position: bottom;
		background-repeat: no-repeat;
	}
}

@media (min-width: 420px) and (max-width: 460px) {
	.data_pick2 {
		left: -90% !important;
	}
}

@media (min-width: 461px) and (max-width: 520px) {
	.data_pick2 {
		left: -80% !important;
	}
}
@media (min-width: 521px) and (max-width: 767px) {
	.data_pick2 {
		left: -50% !important;
	}
}
@media (min-width: 768px) and (max-width: 1020px) {
	.data_pick2 {
		left: -130% !important;
	}
}

@media screen and (max-width: 640px) {
	.rdp {
		--rdp-cell-size: 31px !important;
	}
	.cstmPos.timPick {
		width: 150px;
	}

	.swiper-button-next,
	.swiper-button-prev {
		background-color: white;
		border-radius: 20px;
		/* background-color: rgba(255, 255, 255, 0.5); */
		padding: 16px;
		width: 20px !important;
		height: 20px !important;
		color: #000 !important;
		fill: black !important;
		stroke: black !important;
	}

	.swiper-button-next:after,
	.swiper-button-prev:after {
		font-size: 15px !important;
		font-weight: 700;
	}
}

@media screen and (min-width: 768px) {
	.mySwiper {
		width: 820px !important;
	}
}
@media screen and (max-width: 500px) {
	.blog-article-tags-section {
		height: 32px;
		overflow-x: auto;
		width: 100%;
	}
	.blog-article-tags-section::-webkit-scrollbar {
		display: none;
	}
}

@media screen and (max-width: 850px) {
	.main-page-section-tags-container {
		display: none !important;
	}
}

@media screen and (min-width: 1586px) and (max-width: 3840px) {
	.header {
		border: 1pt solid;
		margin-left: 0px;
		margin-right: 0px;
		width: 100% !important;
	}
}

/* @media screen and (min-width:768px) and (max-width:1021px) {
  .mySwiper{
    width: 800px !important;
  }
} */

/*  */

/* Ranger */
.progress {
	background: linear-gradient(
		to right,
		#82cfd0 0%,
		#82cfd0 40%,
		#fff 40%,
		#fff 100%
	) !important;
}

/* date picker calender CSS */

.ticket::-webkit-calendar-picker-indicator {
	color: rgba(0, 0, 0, 0);
	opacity: 1;
	display: block;
	background: url(./assests/icon.png) no-repeat;
	border-width: thin;
}

/* Nested Dropdown CSS */

.actions > ul {
	transform: translatex(100%) scale(0);
}

.actions:hover > ul {
	transform: translatex(-101%) scale(1);
}

.actions > button svg {
	transform: rotate(-90deg);
}

.actions:hover > button svg {
	transform: rotate(-270deg);
}

.group:hover .group-hover\:scale-100 {
	transform: scale(1);
}

.group:hover .group-hover\:-rotate-180 {
	transform: rotate(180deg);
}

.scale-0 {
	transform: scale(0);
}

.min-w-32 {
	min-width: 8rem;
}

/* Checkbox CSS */
input[type="checkbox"].check_box:checked {
	accent-color: #ff8c00;
	color: white;
}

.main-page-section-tags {
	display: flex;
	margin-top: 10px;
	height: 32px;
	margin-bottom: 16px;
}

.section-tags {
	background: #ffead5;
	display: inline-block;
	/* margin-left: 8px;
    margin-top: 8px; */
	margin-right: 10px;
	vertical-align: top;
	border-radius: 8px;
}

.blog-article-tags-section {
	height: 32px;
	overflow-x: auto;
	max-width: 1440px;
	margin-right: auto;
	margin-left: auto;
}
.blog-article-tags-section::-webkit-scrollbar {
	display: none;
}
.tester {
	width: 659px;
}
.main-page-section-tags-container {
	width: 659px;
}

.section-tags-span {
	align-items: center;
	border-radius: 28px;
	color: #444444;
	display: inline-flex;
	font-size: 13px;
	height: 28px;
	padding: 4px 16px;
	white-space: nowrap;
}
.main-page-section-tags-mobile-view {
	display: none;
}
.latest-blog-section-heading {
	font-family: Open Sans;
	font-size: 31px;
	font-weight: 700;
	line-height: 50px;
	letter-spacing: -0.02em;
	text-align: left;
	margin-bottom: 14px;
	color: #444444;
}
.latest-blog-section-sub-heading {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: -0.02em;
	text-align: left;
	margin-bottom: 20px;
	color: #444444;
}

@media only screen and (max-width: 1200px) {
	.latest-blog-section-articles {
		max-width: 100%;
		overflow-x: auto;
	}
	.latest-blog-section-articles::-webkit-scrollbar {
		width: 2px;
		height: 4px;
	}

	.latest-blog-section-articles::-webkit-scrollbar-track {
		background: #e6e6e6;
		border-radius: 5px;
	}

	.latest-blog-section-articles::-webkit-scrollbar-thumb {
		background-color: #b3b3b3;
		border-radius: 14px;
	}
	.blog-article-card-main {
		width: 379px;
	}
}

/* React day picker */

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
	background-color: #ff8c00 !important; /* Set your desired background color for the selected day */
	color: #ffffff !important; /* Set your desired text color for the selected day */
}

/* Search car detail CSS */
@media screen and (width: 280px) {
	.testimation {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.testimation_list {
		padding-top: 20px;
		padding-left: 0px !important;
	}

	.testimation_img {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.right_Section {
		margin-top: 0px !important;
	}
}

/* Footer CSS */
@media screen and (width: 280px) {
	.banner_img {
		object-fit: contain;
	}

	.list_items {
		display: flex;
		flex-wrap: wrap;
	}

	.list_items_right {
		padding-left: 0 !important;
		padding-bottom: 20px;
	}

	.footer_bottom {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.fotter_bottom_heading {
		margin: 0px !important;
	}
}

/* Home Date Picker CSS */

@keyframes moveUp {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
}

.main-page-votre-mobile-section-heading {
	max-width: 589px;
	font-family: Open Sans;
	font-size: 31px;
	font-weight: 700;
	line-height: 50px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #444444;
}
.main-page-votre-mobile-section-sub-heading {
	max-width: 586px;
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #444444;
	margin-bottom: 20px;
}
/* .main-page-votre-mobile-section-image{
	max-width: 518.33px;
    max-height: 435.58px;
    border-radius: 12px;
    min-width: 380px;
    min-height: 320px;

} */
.main-page-votre-mobile-section {
	width: 659px;
	overflow-x: auto;
}

.main-page-votre-mobile-section::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.main-page-votre-mobile-section::-webkit-scrollbar-track {
	background: #e6e6e6;
	border-radius: 5px;
}

.main-page-votre-mobile-section::-webkit-scrollbar-thumb {
	background-color: #b3b3b3;
	border-radius: 14px;
}
