/* blog page styling */

.blog-page-main {
	max-width: 999px;
	margin-right: auto;
	margin-left: auto;
}

.blog-header-heading-top-line {
	width: 412.26px;
	background: #1d1918;
	border: 1.08px solid #1d1918;
	margin-top: 70px;
}
.blog-header-heading {
	width: 425px;
	margin-top: 15px;
	height: 54px;
	font-family: Open Sans;
	font-size: 40px;
	font-weight: 700;
	line-height: 54px;
	letter-spacing: 0em;
	text-align: left;
	color: #ff8c00;
	cursor: pointer;
}
.blog-heading {
	font-family: Open Sans;
	font-size: 36px;
	font-weight: 700;
	line-height: 50px;
	letter-spacing: -0.02em;
	text-align: center;
	color: #444444;
}
.blog-sub-heading {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: -0.02em;
	text-align: center;
	margin-bottom: 100px;
	color: #444444;

}
.blog-artice-main {
	max-width: 820px;
	margin-left: auto;
	margin-right: auto;
}
.blog-article-card-main {
	/* width: 379px; */
	height: 456px;
	margin-bottom: 45px;
}
.blog-article-card-main-page {
	/* width: 379px; */
	/* height: 456px; */
	margin-bottom: 45px;
}
.blog-article-card-tag {
	width: fit-content;
	height: 32px;
	border-radius: 24px;
	border: 1px solid #444444;
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	padding-right: 1rem;
}

.blog-article-card-tag h1 {
	font-size: 15px !important;
	color: #444444;
}

.blog-article-card-text {
	height: 90px;
	font-family: Open Sans;
	font-size: 22px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #444444;
}
.blog-article-card-text-main-page {
	height: 90px;
	font-family: Open Sans;
	font-size: 22px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #444444;
}

.blog-article-card-text-slider {
	height: 90px;
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #444444;
}
.blog-article-card-text-slider-main-page {
	height: 90px;
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #444444;
}

.blog-article-card-read-time {
	color: #444444;
}

.blog-article-card-date {
	margin-top: 25px;
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	color: #444444;
}
.blog-article-card-button {
	width: 152px;
	height: 32px;
	border-radius: 16px;
	background: #ffead5;
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	color: #444444;
}
.blog-article-grid {
	display: flex;
	justify-content: space-around;
}
.blog-artice-bottom-button {
	width: 184px;
	height: 44px;
	border-radius: 6px;
	padding: 10px 30px 10px 30px;
	background: #ff8c00;
}
.blog-artice-bottom-button-main {
	display: flex;
	justify-content: center;
	margin-bottom: 70px;
	margin-top: 50px;
}
.blog-artice-bottom-button-text {
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #ffffff;
}
.blog-page-head-section {
	margin-top: -70px;
	margin-bottom: 100px;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
}
.blog-page-heading-section {
	width: 425px;
	border-radius: 20px;
}
.blog-page-head-section-image {
	background-image: url("./assests/blog/article-head-section.png");
	height: 100%;
	width: 100%;
	background-size: cover;
}
.blog-page-head-section-heading {
	max-width: 425px;
	font-family: Open Sans;
	font-size: 36px;
	font-weight: 600;
	line-height: 49px !important;
	margin-bottom: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #444444;
	margin-top: 30px;
}
.blog-page-head-apps-buttons-heading-text{
	width: 213px;
	height: 26px;
	font-family: Open Sans;
font-size: 14px;
font-weight: 400;
line-height: 26px;
letter-spacing: -0.02em;
text-align: left;
color: #5A5A5A;
}
.blog-page-head-section-sub-heading {
	max-width: 415px;
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #444444;
	margin-bottom: 40px;
}
.blog-page-heading-section-main {
	position: absolute;
	margin-left: 5rem;
	margin-top: 80px;
}
.blog-page-head-apps-buttons-section {
	max-width: 237.31px;
	margin-top: 50px;
}
.blog-page-head-apps-button {
	max-width: 113.02px;
	height: 36px;
	border-radius: 4px;
}

.blog-page-heading-section-main-mobile {
	display: none;
}
.blog-page-head-button {
	width: 136px;
	height: 40px;
	padding: 10px 30px 12px 23px;
	border-radius: 20px;
	background: #ffead5;
	font-family: Open Sans;
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 20px;
	cursor: pointer;
	color:  #444444;
}
.blog-article-footer-section {
	max-width: 885px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	margin-top: 40px;
}
.blog-header-heading-mobile {
	display: none;
}

.blog-header-heading-mobile_with_banner{
	display: none;
}

.blog-article-like-dislike-main {
	min-width: 348.31px;
	height: 63px;
	padding: 10px;
	border-radius: 4px;
	color: #e0e0e0;
	border: 1px solid #e0e0e0;
}
.blog-article-like-dislike-text {
	max-width: 237.31px;
	height: 43px;
	gap: 4px;
}
.blog-article-like-dislike-heading {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #4e5565;
	margin-bottom: 3px;
	height: 22px;
}
.blog-artical-page-outer{
	font-family: Open Sans;
font-size: 36px;
font-weight: 700;
line-height: 50px;
letter-spacing: -0.02em;
text-align: center;
max-width: 999px;
margin-right: auto;
margin-left: auto;
}
.blog-article-like-dislike-sub-heading {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: #4e5565;
}
.blog-article-like-dislike-buttons-main {
	max-width: 74px;
	height: 30px;
	gap: 14px;
	margin-top: 5px;
}
.blog-article-like-dislike-button-like {
	width: 30px;
	height: 30px;
	padding: 6.25px 7.65px 8.25px 6.5px;
	border-radius: 4px;
	color: #acacac;
	border: 1px solid #acacac;
	cursor: pointer;
}
.blog-article-like-dislike-button-dislike {
	width: 30px;
	height: 30px;
	padding: 7.25px 7.65px 8.25px 5.5px;
	border-radius: 4px;
	color: #acacac;
	border: 1px solid #acacac;
	cursor: pointer;
}
.blog-article-footer-section-button {
	max-width: 268px;
	height: 44px;
	top: 2684px;
	left: 899px;
	padding: 10px 30px 10px 30px;
	border-radius: 6px;
	gap: 8px;
	background: #ff8c00;
}
.blog-article-footer-section-button-text {
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.02em;
	text-align: left;
	color: white;
}
.blog-article-share-buttons-main {
	position: absolute;
	height: 147px;
	display: grid;
	margin-top: 160px;
}
.article-share-buttons{
	display: flex;
	justify-content: end;
	margin-right: 120px;
}

.blog-article-share-button {
	width: 40px;
	height: 40px;
	padding: 12px;
	background: #ff8c00;
	border-radius: 50%;
	opacity: 80%;
	border: 1.5px solid #ff8c00;
}
.share-button-mobile-section{
	display: none;
}

@media screen and (max-width: 1250px) {
	.blog-page-head-section-heading {
		font-size: 30px;
		line-height: 40px ;
		margin-top: 15px;
	}
	.blog-page-head-apps-buttons-section {
		margin-top: 30px;
	}
	.article-share-buttons{
		margin-right: 50px !important;
	}
}
@media screen and (max-width: 1120px) {
	.article-share-buttons{
		margin-right: 0px !important;
	}
}
@media screen and (max-width: 1050px) {
	.article-share-buttons{
		display: none !important;
	}
	.share-button-mobile-section{
		display: flex !important;
		position: fixed !important;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		text-align: center;
		background-color: white;
		padding: 10px;
		width: 100%;
		justify-content: center;
		box-shadow: 0px -2px 11px rgba(0, 0, 0, 0.2);
	}
}

@media screen and (min-width: 1900px) {
	.blog-article-top-heading {
		padding-left: 14.5rem;
	}
}
@media screen and (min-width: 1650px) {
	.blog-article-top-heading {
		padding-left: 10rem;
	}
}

@media screen and (max-width: 1050px) {
	.blog-header-heading-top-line {
		width: 259.26px;
		background: #1d1918;
		border: 1.08px solid #1d1918;
		margin-top: 20px;
	}
	.blog-header-heading {
		margin-top: 10px;
		height: 54px;
		font-size: 30px;
		line-height: 45px;
	}
	.blog-page-head-section-heading {
		font-size: 20px;
		line-height: 30px;
		margin-top: 0px;
		max-width: 220px;
		line-height: 35px !important;
	}
	.blog-page-head-section-sub-heading {
		max-width: 320px;
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 30px;
	}
}
@media screen and (max-width: 900px) {
	.blog-page-head-apps-buttons-section {
		margin-top: 15px;
	}
	
	.blog-header-heading {
		margin-top: 5px;
		height: 40px;
		font-size: 25px;
		line-height: 45px;
	}
	.blog-page-head-section-heading{
		line-height: 30px;
	}
	.blog-page-head-section-sub-heading {
		max-width: 300px;
		margin-bottom: 20px;
	}
}
/* Search car detail CSS */

@media screen and (max-width: 860px) {
	.blog-article-card-main-page{
		width: 280px !important;
		height: 300px !important;
	}
}

@media screen and (max-width: 820px) {

	/* .blog-article-card-main-page{
		width: 280px !important;
		height: 300px !important;
	} */
	.blog-article-card-text-main-page {
		height: 90px;
		font-family: Open Sans;
		font-size: 13px !important;
		font-weight: 400;
		line-height: 17px !important;
		letter-spacing: -0.02em;
		text-align: left;
		color: #444444;
	}
	.blog-article-card-text-slider-main-page {
		height: 54px !important;
		font-family: Open Sans;
		font-size: 13px !important;
		font-weight: 500;
		line-height: 17px !important;
		letter-spacing: -0.02em;
		text-align: left;
		color: #444444;
	}
	.blog-article-card-date-main-page {
		font-family: Open Sans;
		font-size: 12px !important;
		font-weight: 400;
		line-height: 14px !important;
		letter-spacing: 0em;
		text-align: left;
		color: #444444;
		margin-top: 0px;
	}

	.article-share-buttons{
		margin-right: 30px !important;
	}
	
	.blog-article-share-buttons-main {
		margin-top: 180px;
	}
	.blog-heading {
		max-width: 288px;
		font-size: 24px;
		line-height: 28px;
		margin-right: auto;
		margin-left: auto;
		}
	.blog-sub-heading {
		margin-bottom: 30px;
		padding: 16px 20px 16px 20px;
	}
	.blog-artice-main {
		padding: 0px, 20px, 0px, 20px;
	}
	.blog-article-grid {
		display: grid;
		width: 375px;
		margin-left: auto;
		margin-right: auto;
	}
	.blog-article-card-main {
		margin-bottom: 25px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px 0px #0000000d;
	}
	.blog-article-card-main-page {
		margin-bottom: 25px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px 0px #0000000d;
	}
	.blog-article-image {
		border-radius: 8px;
	}
	.blog-artice-bottom-button-main {
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.blog-page-heading-section-main {
		display: none !important;
	}
	.blog-page-heading-section-main-mobile {
		display: block;
		max-width: 820px;
		margin-left: auto;
		margin-right: auto;
	}
	.blog-page-heading-section {
		width: 350px !important;
		margin-right: auto;
		margin-left: auto;
		margin-top: 50px;
	}
	.blog-page-head-section-heading {
		font-family: Open Sans;
		font-size: 24px;
		font-weight: 600;
		line-height: 28px !important;
		letter-spacing: 0em;
		text-align: center;
		max-width: 350px !important;
	}

	.blog-page-head-section-sub-heading {
		font-family: Open Sans;
		font-size: 13px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;
		max-width: 350px !important;
		margin-top: 20px;
	}
	.blog-page-head-button {
		width: 136px;
		height: 40px;
		padding: 10px 30px 12px 23px;
		border-radius: 20px;
		background: #ffead5;
		font-family: Open Sans;
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0em;
		text-align: left;
		margin-top: 20px;
	}

	.blog-page-head-section {
		margin-top: 0px !important;
		margin-bottom: 30px !important;
	}

	.blog-header-heading-mobile {
		display: block !important;
		position: absolute;
		top: 100px;
		left: 5rem;
		font-size: 28px;
		color: #ff8c00;
		font-family: Open Sans;
		font-size: 28px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;
	}

	.blog-header-heading-mobile_with_banner {
		display: block !important;
		position: absolute;
		top: 186px;
		left: 5rem;
		font-size: 28px;
		color: #ff8c00;
		font-family: Open Sans;
		font-size: 28px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;
	}
	.blog-article-footer-section {
		display: grid !important;
		max-width: 350px !important;
	}
	.blog-article-footer-section-button {
		margin-top: 15px;
		margin-left: auto;
		margin-right: auto;
	}

}
@media screen and (max-width: 460px) {
	.blog-header-heading-mobile {
		top: 70px !important;
		left: 3rem !important;
		font-size: 28px;
		color: #ff8c00;
		font-family: Open Sans;
		font-size: 28px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;
	}
	.blog-header-heading-mobile_with_banner {
		top: 156px !important;
		left: 3rem !important;
		font-size: 28px;
		color: #ff8c00;
		font-family: Open Sans;
		font-size: 28px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;
	}
	.blog-article-card-main-page{
		width: 230px !important;
		height: 270px !important;
	}
	.blog-article-card-text-main-page {
		height: 90px;
		font-family: Open Sans;
		font-size: 13px !important;
		font-weight: 400;
		line-height: 17px !important;
		letter-spacing: -0.02em;
		text-align: left;
		color: #444444;
	}
	.blog-article-card-text-slider-main-page {
		height: 54px !important;
		font-family: Open Sans;
		font-size: 13px !important;
		font-weight: 500;
		line-height: 17px !important;
		letter-spacing: -0.02em;
		text-align: left;
		color: #444444;
	}
	.blog-article-card-date-main-page {
		font-family: Open Sans;
		font-size: 12px !important;
		font-weight: 400;
		line-height: 14px !important;
		letter-spacing: 0em;
		text-align: left;
		color: #444444;
		margin-top: 0px;
	}
}

/* blog-artical-page-heading */
.blog-article-page-title-heading-outer {
	max-width: 999px;
	margin-left: auto;
	margin-right: auto;
	font-family: Open Sans;
	font-size: 36px;
	font-weight: 700;
	line-height: 50px;
	letter-spacing: -0.02em;
	text-align: center;
	color: #444444;
}
.blog-article-page-title-sub-heading-inner {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: -0.02em;
	text-align: center;
	color: #444444;
}
.blog-article-image-1 {
	max-width: 840px;
	margin-left: auto;
	margin-right: auto;
}

.article-date-time {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 600;
	line-height: 26px;
	letter-spacing: -0.02em;
	text-align: center;
	color: #444444;
}
.article-text {
	max-width: 885px;
	margin-left: auto;
	margin-right: auto;
	color: #444444;
}
.article-text p {
	font-family: Open Sans;
	font-size: 15px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.1em;
	text-align: left;
	margin-bottom: 20px;
}
.article-text h3 {
	font-family: Open Sans;
	font-size: 24px;
	font-weight: 600;
	line-height: 26px;
	letter-spacing: 0.1em;
	text-align: left;
	margin-bottom: 30px;
	margin-top: 30px;
}

.blog-article-header-heading-mobile {
	font-family: Open Sans;
	font-size: 28px;
	font-weight: 700;
	line-height: 38px;
	letter-spacing: 0em;
	text-align: center;
	color: #ff8c00;
	display: none;
}

@media screen and (max-width: 420px) {
	.blog-article-heading-mobile-paddidng {
		padding-top: 12px !important ;
	}
	.hide-on-mobile {
		display: none;
	}
	.blog-article-header-heading-mobile {
		display: block;
	}
	.blog-article-page-title-heading-outer {
		max-width: 315px;
		font-size: 22px;
		font-weight: 600;
		line-height: 24px;
	}
	.blog-article-page-title-sub-heading-inner {
		max-width: 283px;
		font-size: 13px;
		line-height: 24px;
		margin-left: auto;
		margin-right: auto;
	}
	.article-date-time {
		font-size: 12px;
	}
	.article-text {
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
		color: #444444;
	}
	.article-text p {
		font-size: 13px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.05em;
		text-align: left;
	}
	.article-text h3 {
		max-width: 350px;
		font-size: 22px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0em;
		text-align: left;
		margin-bottom: 10px !important;
	}

}